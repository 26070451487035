<template>
  <svg
    class="hamburger-svg"
    :height="height"
    version="1.1"
    viewBox="0 0 40 45"
    :width="width"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg"
  >
    <g id="layer1">
      <rect
        height="10"
        style="fill:#000000;fill-opacity:1"
        width="40"
        x="0"
        y="0"
      />
      <rect
        height="10"
        style="fill:#000000;fill-opacity:1"
        width="40"
        x="0"
        y="17"
      />
      <rect
        height="10"
        style="fill:#000000;fill-opacity:1"
        width="40"
        x="0"
        y="34"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: () => '40'
    },
    width: {
      type: String,
      default: () => '40'
    }
  }
};
</script>
