<template>
  <div class="events p-0">
    <column-header red :title="$t('content.events.title')" />
    <p class="p-3">
      Begleitend zu den Ausstellungen bietet die Städtische Galerie Führungen,
      Kunstgespräche, Vorträge, Workshops und Veranstaltungen für alle Alters-
      gruppen an. Dazu gehören Werkstatt- und Künstlergespräche ebenso wie
      theoretische Seminare, Praxiskurse zum Töpfern oder Speckstein-
      bearbeitung sowie die Ferienspiele.
      <br />
      <br />
      In den Ferien sind Kinder und Jugend- liche zu den Ferienspielen
      eingeladen. Im Verlauf der mehrtägigen Veran- staltungen werden
      Ausstellungen besucht und verschiedene Kunst- richtungen sowie
      Künstlerinnen und Künstler vorgestellt. Die Betrachtung und Interpretation
      der Werke finden ihre Fortsetzung im freien Gestalten und der Neuschöpfung
      von eigenen Werken, die während eines Abschluss- festes Freunden und
      Verwandten vorgestellt werden.
    </p>
    <hr />
    <calender />
  </div>
</template>
<script>
import Calender from './Calender.vue';
import ColumnHeader from './ColumnHeader';
export default {
  components: { ColumnHeader, Calender }
};
</script>
