<template>
  <div :id="anchor" ref="expansionTextPanel" class="expansion-text-panel">
    <div class="expansion-text-panel__header" @click="togglePanel()">
      <slot name="header"> </slot>
    </div>
    <div
      ref="expansionTextPanelContent"
      class="expansion-text-panel__content"
      :class="{ collapse: collapsed }"
    >
      <slot>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam amet
        animi at consequatur consequuntur cum cupiditate deserunt dolor eaque
        earum itaque, nihil quasi qui rem sed similique sint voluptas voluptate.
      </slot>
    </div>
    <div
      v-if="isNessary"
      class="expansion-panel__extender pt-2"
      @click="togglePanel()"
    >
      <div v-if="collapsed" class="small center color-grey">weiterlesen</div>
      <expansion-panel-svg
        :class="{
          active: !collapsed
        }"
        :collapse="collapsed"
      />
    </div>
  </div>
</template>

<script>
import ExpansionPanelSvg from '@/components/expansionPanel/ExpansionPanelSvg';

export default {
  name: 'expansion-text-panel',
  components: { ExpansionPanelSvg },
  data() {
    return {
      collapsed: true,
      isNessary: true,
      closedHeight: 114
    };
  },
  props: {
    subpage: {
      type: String,
      required: true
    },
    anchor: {
      type: String,
      default: () => ''
    },
    red: {
      type: String,
      default: () => null
    },
    green: {
      type: String,
      default: () => null
    },
    blue: {
      type: String,
      default: () => null
    }
  },
  computed: {
    activeClasses() {
      return this.activeClass && !this.collapsed
        ? `active ${this.activeClass}`
        : '';
    }
  },
  mounted() {
    const content = this.$refs['expansionTextPanelContent'];
    // get object from inside the slot `expansionTextPanelContent`
    const innerContent = content.querySelector('*');
    this.closedHeight = innerContent.offsetHeight;
    // set defalt for the open animation whit css
    content.style.setProperty('height', `${this.closedHeight}px`);
    // set for crome bug with '-webkit-box'
    content.firstChild.style.overflow = 'hidden';

    if (innerContent.scrollHeight <= this.closedHeight) {
      this.isNessary = false;
    } else {
      // set for firefox bug with '-webkit-box'
      content.firstChild.children[0].style.overflow = 'hidden';
    }
    // when page load with hash from the current content then open expansionTextPanel
    if (this.$route.hash && this.$route.hash.indexOf(this.anchor) > 0) {
      this.collapsed = false;
      content.style.setProperty('height', `${innerContent.scrollHeight}px`);
    }
  },
  methods: {
    togglePanel() {
      if (this.collapsed) {
        if (
          this.$route.fullPath.indexOf(`${this.subpage}#${this.anchor}`) === -1
        )
          this.$router.push(`${this.subpage}#${this.anchor}`);

        this.openPanel();
      } else {
        this.closePanel();
      }

      this.$refs['expansionTextPanel'].scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    },
    closePanel() {
      this.collapsed = true;
      // set for the close animation whit css
      const content = this.$refs['expansionTextPanelContent'];
      content.style.setProperty('height', `${this.closedHeight}px`);
    },
    openPanel() {
      this.collapsed = false;
      // set for the open animation whit css
      const content = this.$refs['expansionTextPanelContent'];
      const innerContent = content.querySelector('*');
      content.style.setProperty('height', `${innerContent.scrollHeight}px`);
    },
    isActive(value) {
      return value || typeof value === 'string';
    }
  },
  watch: {
    '$route.fullPath': {
      handler: function() {
        if (this.$route.hash && !!this.$refs.expansionTextPanelContent) {
          if (this.$route.hash.indexOf(this.anchor) < 0) {
            this.closePanel();
          } else if (
            this.$route.hash.indexOf(this.anchor) == 1 &&
            this.anchor == 'contact-datenschutzerklaerung'
          ) {
            this.openPanel();
          }
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import '../../style/color';

.expansion-text-panel {
  padding-left: 1em;
  padding-right: 1em;
}

.expansion-text-panel__content {
  overflow: hidden;
  transition: height 1000ms ease-out;
}
.expansion-text-panel__content.collapse {
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.expansion-panel-svg,
.expansion-text-panel__header {
  cursor: pointer;
}

.expansion-panel-svg.active.active-red {
  fill: $red;
}
.expansion-panel-svg.active.active-green {
  fill: $green;
}
.expansion-panel-svg.active.active-blue {
  fill: $blue;
}
</style>
