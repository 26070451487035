<template>
  <div ref="expansionPanel" class="expansion-panel px-3">
    <div
      class="expansion-panel__header"
      :class="{ 'expansion-panel__header--active': !collapsed }"
      @click="togglePanel()"
    >
      <slot name="header" />
      <div
        v-if="collapsed && subpage == 'pressarea'"
        class="small center color-grey"
      >
        {{ $t('content.readmore') }}
      </div>
    </div>
    <expansion-panel-svg
      :class="{
        active: !collapsed
      }"
      :collapse="collapsed"
      @click.native="togglePanel()"
    />
    <transition
      name="expand"
      @after-enter="afterEnter"
      @enter="enter"
      @leave="afterLeave"
    >
      <div
        v-if="!collapsed"
        class="expansion-panel__content"
        :style="componentStyles"
      >
        <div ref="content" class="expansion-panel__content_inner"><slot /></div>
      </div>
    </transition>
  </div>
</template>

<script>
import ExpansionPanelSvg from '@/components/expansionPanel/ExpansionPanelSvg';
export default {
  name: 'expansion-panel',
  components: { ExpansionPanelSvg },
  data() {
    return {
      collapsed: true,
      componentStyles: {
        height: 0
      }
    };
  },
  props: {
    subpage: {
      type: String,
      required: true
    },
    anchor: {
      type: String,
      default: () => null
    },
    red: {
      type: String,
      default: () => null
    },
    green: {
      type: String,
      default: () => null
    },
    blue: {
      type: String,
      default: () => null
    }
  },
  computed: {
    activeClasses() {
      return this.activeClass && !this.collapsed
        ? `active ${this.activeClass}`
        : '';
    }
  },
  mounted() {
    if (
      this.$route.hash &&
      this.anchor &&
      this.$route.hash.indexOf(`#${this.anchor}`) > -1
    ) {
      this.togglePanel();
    }
  },
  methods: {
    async togglePanel() {
      // check is current panel close
      if (this.collapsed) {
        // change url
        let url = this.anchor ? `${this.subpage}#${this.anchor}` : this.subpage;
        if (this.$route.fullPath.indexOf(url) === -1) {
          await this.$router.push(url);
        }
        // open panel
        this.collapsed = !this.collapsed;
      } else {
        // panel is open
        this.closePanel();
      }
    },
    closePanel() {
      // set default height for 'ElementDistance'
      this.$refs.content.style.height = this.getContentHeight();
      this.closeAnimate = setInterval(() => {
        let ElementDistance = this.$refs.content.style.height.slice(0, -2);
        let speed = 50;

        // speed reduce
        if (ElementDistance < 20) {
          speed = 2;
        } else if (ElementDistance < 100) {
          speed = 14;
        } else if (ElementDistance > 500) {
          speed = 100;
        }

        this.$refs.content.style.height = ElementDistance - speed + 'px';

        // panel close
        if (ElementDistance < 2) {
          clearInterval(this.closeAnimate);
          this.$refs.content.style.height = 0;
          this.collapsed = !this.collapsed;
        }
      }, 20);
    },

    async enter() {
      this.setHeight(this.getContentHeight());
    },

    async afterEnter() {
      this.setHeight(undefined);
    },

    afterLeave() {
      this.setHeight('0');
    },

    setHeight(height) {
      this.componentStyles.height = height;
    },
    getContentHeight() {
      return this.$refs.content.scrollHeight + 'px';
    },
    isActive(value) {
      return value || typeof value === 'string';
    }
  },
  watch: {
    '$route.fullPath': {
      handler: function() {
        if (
          this.$route.hash &&
          this.anchor &&
          !!this.$refs.content &&
          !this.$route.hash.indexOf(`#${this.anchor}`) > -1
        ) {
          this.closePanel();
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import '../../style/color';

.expansion-panel__header,
.expansion-panel-svg {
  cursor: pointer;
}

.expansion-panel__content {
  display: block;
  height: auto;
  overflow: hidden;
}

.expansion-panel__content.expand-enter-active,
.expansion-panel__content.expand-leave-active {
  transition: all 1s ease-in;
}

.expansion-panel__content.expand-leave-active {
  height: 0 !important;
}

.expansion-panel-svg.active.active-red {
  fill: $red;
}
.expansion-panel-svg.active.active-green {
  fill: $green;
}
.expansion-panel-svg.active.active-blue {
  fill: $blue;
}
</style>
