<template>
  <div id="navigation-desktop">
    <b-sidebar
      id="navigation-sidebar-desktop"
      class="d-none d-sm-block"
      no-close-on-backdrop
      no-close-on-esc
      no-close-on-route-change
      shadow="lg"
      :visible="true"
    >
      <navigation-sidebar-content @changeRoute="goTo($event)" />
    </b-sidebar>
  </div>
</template>

<script>
import NavigationSidebarContent from './NavigationSidebarContent';

export default {
  components: {
    NavigationSidebarContent
  },
  methods: {
    goTo(link) {
      if (this.$route.fullPath !== '/' + link) {
        this.$router.push(link);
      }
    }
  }
};
</script>

<style lang="scss">
@import '../../style/main';

#navigation-sidebar-desktop {
  width: $navigationWidth + px !important;
}
#navigation-sidebar-desktop.shadow-lg {
  box-shadow: $navigationShadow;
  -webkit-box-shadow: $navigationShadow;
  -moz-box-shadow: $navigationShadow;
}

#navigation-sidebar-desktop .b-sidebar-header {
  display: none;
}
</style>
