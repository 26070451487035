<template>
  <svg
    class="exhibitions-banner-extended-en"
    data-name="Ebene 1"
    viewBox="0 0 136 80"
    xmlns="http://www.w3.org/2000/svg"
  >
    <polygon
      class="cls-1"
      points="136 76.86 136 44.52 58.88 0 2.88 0 136 76.86"
    />
    <g class="cls-2">
      <path
        class="cls-3"
        d="M54.7,20.68l5.78-10,5.24,3-1,1.69-3.33-1.92-1.33,2.3,2.54,1.46-1,1.7-2.54-1.47L57.59,20.1l3.47,2-1,1.69Z"
      />
      <path
        class="cls-3"
        d="M68.68,19.4l3.17-2.17,2.05,1.19-5.17,3.34-.38,6.8-2.06-1.19.38-4.49L63,25.45,60.9,24.26l5.73-3.72.28-6.15,2,1.18Z"
      />
      <path
        class="cls-3"
        d="M80.91,22.47l-1,1.69L77.7,22.87l-4.8,8.32L71,30.08l4.8-8.31-2.23-1.29,1-1.69Z"
      />
      <path
        class="cls-3"
        d="M76.26,33.13l5.78-10,5.24,3-1,1.69L83,25.92l-1.33,2.3,2.54,1.46-1,1.69-2.54-1.46-1.52,2.63,3.47,2-1,1.7Z"
      />
      <path
        class="cls-3"
        d="M83.3,37.19l5.78-10,2.1,1.2-.77,7.47,0,0L93.86,30l1.75,1L89.83,41,88,39.91l.81-8.15,0,0L85.05,38.2Z"
      />
      <path
        class="cls-3"
        d="M97.44,32l2.66,1.54c2.19,1.26,2.86,2.91,1.49,5.48l-1.78,3.08c-1.54,2.47-3.3,2.71-5.49,1.44L91.66,42Zm-2.89,9.42.66.39c1.06.6,2,.46,2.82-1l1.52-2.63c.82-1.42.68-2.27-.51-3l-.67-.38Z"
      />
      <path
        class="cls-3"
        d="M99.36,46.47l5.78-10,5.24,3-1,1.69-3.32-1.92-1.33,2.3L107.29,43l-1,1.69-2.54-1.46-1.52,2.63,3.47,2-1,1.69Z"
      />
      <path
        class="cls-3"
        d="M112.07,40.46,114.73,42c2.19,1.27,2.87,2.92,1.49,5.49l-1.77,3.07C112.91,53,111.14,53.27,109,52l-2.66-1.53Zm-2.89,9.42.67.38c1.05.61,2,.46,2.81-1l1.52-2.64c.82-1.41.68-2.26-.51-3l-.66-.39Z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ExhibitionsBannerExtendedEn'
};
</script>
<style lang="scss">
.exhibitions-banner-extended-en {
  position: absolute;
  top: 0;
  right: 0;
  width: 136px;
  .cls-1 {
    fill: #ffcd37;
  }
  .cls-2 {
    isolation: isolate;
  }
  .cls-3 {
    fill: #fff;
  }
}
</style>
