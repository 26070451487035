import * as axios from 'axios';

export default class LeancmsSdk {
  domain = null;

  constructor(domain) {
    if (!domain) throw new Error('no domain defined!');
    this.domain = domain;
  }

  getFile(path) {
    return axios.get('https://' + this.domain + path).then(value => value.data);
  }

  getUrl(subPath) {
    return `https://${this.domain}/${subPath}`;
  }
}
