<template>
  <div id="ausstellungen" class="exhibitions">
    <expansion-panel
      v-for="(exhibition, index) of getFilteredExhibitions"
      :id="exhibition._leancms.slug"
      :key="index"
      :anchor="exhibition._leancms.slug"
      class="exhibition py-2"
      red
      subpage="ausstellungen"
    >
      <template v-slot:header>
        <img
          :alt="exhibition.title"
          class="exhibition-img"
          :src="getPoster(exhibition)"
        />
        <span v-if="isComingSoon(exhibition)">
          <exhibitions-banner-coming-soon-de v-if="$i18n.locale === 'de_DE'" />
          <exhibitions-banner-coming-soon-en v-else />
        </span>
        <span v-if="exhibition.is_extended">
          <exhibitions-banner-extended-de v-if="$i18n.locale === 'de_DE'" />
          <exhibitions-banner-extended-en v-else />
        </span>
        <is-ready
          v-if="index === getFilteredExhibitions.length - 1"
          content-name="exhibitions"
        />
      </template>
      <template>
        <exhibitions-content :exhibition="exhibition" />
      </template>
    </expansion-panel>
  </div>
</template>

<script>
import ExpansionPanel from '../expansionPanel/ExpansionPanel';
import ExhibitionsContent from './ExhibitionsContent';
import IsReady from '../IsReady';
import ExhibitionsBannerComingSoonDe from './ExhibitionsBannerComingSoonDe.vue';
import ExhibitionsBannerComingSoonEn from './ExhibitionsBannerComingSoonEn.vue';
import ExhibitionsBannerExtendedDe from './ExhibitionsBannerExtendedDe.vue';
import ExhibitionsBannerExtendedEn from './ExhibitionsBannerExtendedEn.vue';
import dayjs from 'dayjs';

export default {
  name: 'Exhibitions',
  components: {
    IsReady,
    ExhibitionsContent,
    ExpansionPanel,
    ExhibitionsBannerComingSoonDe,
    ExhibitionsBannerComingSoonEn,
    ExhibitionsBannerExtendedDe,
    ExhibitionsBannerExtendedEn
  },
  data() {
    return {
      exhibitions: []
    };
  },
  mounted() {
    this.loadExhibitions();
  },
  computed: {
    getFilteredExhibitions() {
      return this.exhibitions.reduce((list, curr) => {
        if (this.title(curr) && this.content(curr) && this.isActive(curr))
          list.push(curr);
        return list;
      }, []);
    }
  },
  methods: {
    async loadExhibitions() {
      this.exhibitions = await this.$store.state.sdk.getFile(
        `/aggregations/exhibitions/active/de_DE/index.json`
      );
    },
    getPoster(exhibition) {
      return this.$store.state.sdk.getUrl(exhibition.poster._default.path);
    },
    title(item) {
      return this.$store.state.sdk.getLanguageProperty(item, 'title');
    },
    content(item) {
      return this.$store.state.sdk.getLanguageProperty(item, 'content');
    },
    isComingSoon(item) {
      const date = dayjs(item.start_date, [
        'DD.MM.YYYY HH:mm',
        'YYYY-MM-DD HH:mm',
        'YYYY-MM-DD'
      ]);
      if (date.isValid) {
        return dayjs().isBefore(date);
      }
      return false;
    },
    isActive(item) {
      const date = dayjs(item.end_date, [
        'DD.MM.YYYY HH:mm',
        'YYYY-MM-DD HH:mm',
        'YYYY-MM-DD'
      ]);
      if (date.isValid) {
        return dayjs().isBefore(date);
      }
      return false;
    }
  }
};
</script>
<style lang="scss">
.exhibitions {
  img {
    cursor: pointer;
    width: 100%;
  }
  .expansion-panel:last-child {
    min-height: 100%;
  }
  .expansion-panel__header {
    position: relative;
  }
  .exhibition-img__banner {
    color: #fff;
    display: block;
    height: 135px;
    position: absolute;
    right: 12px;
    text-indent: -999em;
    top: 10px;
    width: 135px;
    z-index: 1;
  }
  .exhibition-img__banner-de {
    background: transparent url(../../assets/extended_de_DE.png) top left
      no-repeat;
  }
  .exhibition-img__banner-en {
    background: transparent url(../../assets/extended_en_US.png) top left
      no-repeat;
  }
}
</style>
