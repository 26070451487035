<template>
  <div class="logo uppercase">
    <h1>Städtische Galerie<br />Wolfsburg</h1>
    <div class="logo-underline my-3" />
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.logo {
  cursor: pointer;
}
</style>
