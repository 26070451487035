<template>
  <div>
    <h2
      class="mb-0 uppercase"
      :class="{
        'color-r': isActive(red),
        'color-g': isActive(green),
        'color-b': isActive(blue)
      }"
    >
      {{ title }}
    </h2>
    <div
      class="subheader-underline border-color"
      :class="{
        'border-color-r': isActive(red),
        'border-color-g': isActive(green),
        'border-color-b': isActive(blue)
      }"
    />
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    red: {
      type: String,
      default: () => null
    },
    green: {
      type: String,
      default: () => null
    },
    blue: {
      type: String,
      default: () => null
    }
  },
  methods: {
    isActive(value) {
      return value || typeof value === 'string';
    }
  }
};
</script>
<style scoped>
h2 {
  padding-top: 32px;
}
</style>
