<template>
  <div
    :id="`editions-${year}-${edition._leancms.slug}`"
    class="editions-year-edition mb-5"
  >
    <h3 class="mb-3">{{ edition.firstname }} {{ edition.lastname }}</h3>
    <div v-if="edition.exhibitions">
      {{ $t('content.editions.exhibition') }}: <br />
      <router-link :to="`archive#archive-${year}`">
        {{ exhibitionTitle }}
      </router-link>
    </div>
    <div class="mb-3">
      <gallery-carousel v-if="edition.image" :gallery="[edition.image]" />
      <div>{{ edition.title }}</div>
    </div>
    <div v-if="edition.technic">{{ edition.technic }}</div>
    <div v-if="edition.measurements">{{ edition.measurements }}</div>
    <div v-if="edition.origin">
      {{ $t('content.editions.origin') }}: {{ edition.origin }}
    </div>
    <div v-if="edition.edition">
      {{ $t('content.editions.edition') }}: {{ edition.edition }}
    </div>
    <div v-if="edition.price">{{ edition.price }}</div>
  </div>
</template>

<script>
import GalleryCarousel from '../gallery/GalleryCarousel.vue';

export default {
  components: { GalleryCarousel },
  name: 'EditionsYearEdition',
  props: {
    edition: {
      type: Object,
      require: true
    },
    year: {
      type: String,
      require: true
    }
  },
  computed: {
    exhibitionTitle() {
      return this.$store.state.sdk.getLanguageProperty(
        this.edition.exhibitions,
        'title'
      );
    }
  }
};
</script>
<style lang="scss">
.editions-year-edition {
  h3 {
    text-transform: uppercase;
  }
  .gallery-carousel {
    margin: 0 !important;
    .slide-image__text {
      display: flex;
    }
  }
}
</style>
