<template>
  <svg
    class="exhibitions-banner-extended-de"
    data-name="Ebene 1"
    viewBox="0 0 136 80"
    xmlns="http://www.w3.org/2000/svg"
  >
    <polygon
      class="cls-1"
      points="136 76.86 136 44.52 58.88 0 2.88 0 136 76.86"
    />
    <g class="cls-2">
      <path
        class="cls-3"
        d="M52.51,6.07,54.4,7.16,52,15.33l0,0L57.93,9.2l1.88,1.08-8.33,8.54L49.3,17.56Z"
      />
      <path
        class="cls-3"
        d="M55.09,20.91l5.78-10,5.24,3-1,1.69-3.32-1.92L60.48,16,63,17.46l-1,1.69L59.5,17.69,58,20.32l3.47,2-1,1.69Z"
      />
      <path
        class="cls-3"
        d="M66.51,27.5l.69-5-.91-.52L63.93,26,62,24.91l5.78-10,2.77,1.6c2.3,1.33,2.76,3.07,1.67,4.95a2.79,2.79,0,0,1-3,1.68l-.69,5.55Zm.69-7.14,1,.56a1.41,1.41,0,0,0,2.16-.6,1.4,1.4,0,0,0-.56-2.17l-1-.57Z"
      />
      <path
        class="cls-3"
        d="M69.72,29.35l5.78-10,1.92,1.1-4.81,8.32,3.08,1.77-1,1.7Z"
      />
      <path
        class="cls-3"
        d="M75.2,32.51l8.26-8.57,2.45,1.41L82.54,36.76,80.71,35.7l.74-2.39L78.8,31.78,77,33.58ZM85.47,22.09l-.91,1.58-1.67-1,.91-1.58Zm-5.35,8.2,1.9,1.1,1.41-4.58,0,0Zm8-6.68-.91,1.58-1.66-1,.91-1.58Z"
      />
      <path
        class="cls-3"
        d="M83.69,37.42l5.78-10,2.09,1.21-.76,7.47h0l3.42-5.93,1.75,1-5.78,10-1.83-1.06L89.2,32h0l-3.73,6.46Z"
      />
      <path
        class="cls-3"
        d="M97.31,44.17A2.71,2.71,0,0,1,95,44.11C93.1,43,92.31,41.4,93.74,38.93l2.06-3.58c1.43-2.46,3.24-2.6,5.1-1.53S103.26,36.68,102,39l-1.92-1.11c.75-1.29.52-2-.18-2.37s-1.42-.42-2.06.69l-2.35,4.07c-.64,1.11-.34,1.7.43,2.14a1.38,1.38,0,0,0,2-.72l.35-.61-1.34-.77.91-1.58,3.26,1.88-3,5.13-1-.58Z"
      />
      <path
        class="cls-3"
        d="M99.75,46.69l5.78-10,5.24,3-1,1.69-3.32-1.92-1.33,2.3,2.53,1.46-1,1.69-2.54-1.46-1.52,2.63,3.47,2-1,1.7Z"
      />
      <path
        class="cls-3"
        d="M111.17,53.28l.69-5-.91-.53-2.36,4.07-1.91-1.1,5.78-10,2.77,1.6c2.3,1.33,2.76,3.07,1.67,5a2.81,2.81,0,0,1-3,1.68l-.69,5.55Zm.69-7.14,1,.57a1.6,1.6,0,0,0,1.6-2.78l-1-.56Z"
      />
      <path
        class="cls-3"
        d="M126,48.48l-1,1.69-2.23-1.29L118,57.19,116,56.09l4.8-8.32-2.23-1.29,1-1.69Z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ExhibitionsBannerExtendedDe'
};
</script>
<style lang="scss">
.exhibitions-banner-extended-de {
  position: absolute;
  top: 0;
  right: 0;
  width: 136px;
  .cls-1 {
    fill: #ffcd37;
  }
  .cls-2 {
    isolation: isolate;
  }
  .cls-3 {
    fill: #fff;
  }
}
</style>
