import Vue from 'vue';
import Vuex from 'vuex';
import WolfsburgSdk from '../helper/WolfsburgSdk';
import router from '../router/index';
import VueScrollTo from 'vue-scrollto';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    home: null,
    navigation: {
      links: [
        {
          id: 0,
          route: 'ausstellungen',
          componentName: 'Exhibitions',
          showInNavigation: true,
          navigationlabel: 'Exhibitions',
          color: 'red'
        },
        {
          id: 1,
          route: 'aktuelles',
          title: 'News',
          componentName: 'News',
          showInNavigation: true,
          navigationlabel: 'News',
          color: 'red'
        },
        {
          id: 2,
          route: 'veranstaltungen',
          title: 'Events',
          componentName: 'Events',
          showInNavigation: true,
          navigationlabel: 'Events',
          color: 'red'
        },
        {
          id: 3,
          route: 'institutionelles',
          title: 'Institutionelles',
          componentName: 'Institution',
          showInNavigation: true,
          navigationlabel: 'Institution',
          color: 'red'
        },
        {
          id: 4,
          route: 'kontakt',
          title: 'Kontakt',
          componentName: 'Contact',
          showInNavigation: true,
          navigationlabel: 'Contact',
          color: 'red'
        },
        {
          id: 5,
          route: 'kontakt',
          hash: '#contact-datenschutzerklaerung',
          title: 'Datenschutz',
          showInNavigation: true,
          navigationlabel: 'PrivacyPolicy',
          color: 'red'
        },
        {
          id: 6,
          route: 'archive',
          title: 'Archiv',
          showInNavigation: true,
          navigationlabel: 'Archive',
          componentName: 'Archive',
          color: 'red'
        },
        {
          id: 7,
          route: 'editionen',
          title: 'Editionen',
          componentName: 'Editions',
          showInNavigation: true,
          navigationlabel: 'Editions',
          color: 'red'
        },
        {
          id: 8,
          route: 'pressarea',
          title: 'Pressebereich',
          showInNavigation: true,
          componentName: 'Pressarea',
          navigationlabel: 'Pressarea',
          color: 'red'
        }
      ],
      currentComponentId: 0
    },
    sdk: null,
    ready: {
      exhibitions: false,
      news: false,
      events: false,
      institution: false,
      contact: false,
      pressarea: false,
      archive: false,
      editions: false
    }
  },
  mutations: {
    setCurrentComponentId(state, id) {
      state.navigation.currentComponentId = id;
      scrollToContent(state, id);
    },
    initSdk(state, { language, cdn }) {
      state.sdk = new WolfsburgSdk(cdn, language);
    },
    setIsReady(state, param) {
      state.ready[param] = true;
    },
    setHomeData(state, payload) {
      state.home = payload;
    }
  },
  actions: {},
  modules: {}
});

function scrollToContent(state, id) {
  const searchedComponent = state.navigation.links.find(
    component => id === component.id
  );
  if (searchedComponent) {
    const hash =
      searchedComponent.hash?.split('#').pop() ||
      router.app._route.hash?.split('#').pop() ||
      '';
    const element = hash ? hash : searchedComponent.route;
    VueScrollTo.scrollTo(`#${element}`, {
      x: true,
      force: true,
      lazy: false,
      onDone: () => {
        let focus = `.column`;
        // only need for Desktop scrolling to hash like Datenschutzerklärung under Kontakt
        if (hash) {
          focus = `#${element}`;
        }

        VueScrollTo.scrollTo(focus, {
          container: `#${searchedComponent.route}`
        });
      }
    });
  }
}
