<template>
  <button class="button" :class="{ 'button--active': isActive }">
    {{ label }}
  </button>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    },
    isActive: {
      type: Boolean,
      default: () => false
    }
  }
};
</script>
<style lang="scss" scoped>
.button {
  background: white;
  border: 1px solid dimgray;
  color: dimgray;
  font-size: 12px;
  line-height: 12px;
  font-family: Merriweather-Regular, sans-serif;
  height: 15px;
  width: 115px;
  &--active,
  &:hover {
    background: dimgray;
    color: white;

    &:hover {
      text-decoration: underline;
    }
  }
  &:focus {
    outline: 0px;
  }
}
</style>
