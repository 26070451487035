<template>
  <div class="pressarea-exhibition-image mt-4 mb-5">
    <img class="pb-3" :src="imageThumbnail" />
    <div class="pressarea-exhibition-images-image__info">
      <div class="pb-3">
        <span v-if="description">
          {{ description }}
        </span>
        <span v-if="copyright">&#169; {{ copyright }}</span>
      </div>
    </div>
    <div v-if="imageUrl" class="pressarea-exhibition-images-image__download">
      <a download :href="imageUrl">
        {{ $t('content.pressarea.download') }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PressareaExhibition',
  props: {
    exhibitionImage: {
      type: Object,
      require: true
    }
  },
  computed: {
    imageThumbnail() {
      let image = this.exhibitionImage.image?.small;
      if (image?.error) {
        image = this.exhibitionImage.image._default;
      }
      return this.$store.state.sdk.getUrl(image.path);
    },
    image() {
      return !this.exhibitionImage.image._default.error
        ? this.exhibitionImage.image._default
        : null;
    },
    imageUrl() {
      return !this.image.error
        ? this.$store.state.sdk.getUrl(this.image.path)
        : null;
    },
    description() {
      return this.$store.state.sdk.getLanguageProperty(
        this.exhibitionImage,
        'caption'
      );
    },
    copyright() {
      return this.$store.state.sdk.getLanguageProperty(
        this.exhibitionImage,
        'copyright'
      );
    }
  }
};
</script>
<style lang="scss">
.pressarea-exhibition-image {
  img {
    width: 100%;
  }

  .pressarea-exhibition-images-image__info {
    span {
      display: block;
    }
  }
}
</style>
