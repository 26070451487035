<template>
  <div class="archive-exhibitions">
    <expansion-panel
      v-for="(exhibition, i) of exhibitions"
      :key="i"
      class="exhibition-archive py-2"
      red
      subpage="archive"
    >
      <template v-slot:header>
        <img
          :alt="exhibition.titl_de"
          class="exhibition-img"
          :src="getPoster(exhibition)"
        />
      </template>
      <template>
        <exhibitions-content :exhibition="exhibition" />
      </template>
    </expansion-panel>
  </div>
</template>

<script>
import ExpansionPanel from '../expansionPanel/ExpansionPanel.vue';
import ExhibitionsContent from '../exhibitions/ExhibitionsContent.vue';
export default {
  components: { ExpansionPanel, ExhibitionsContent },
  name: 'ArchiveExhibitions',
  props: {
    exhibitions: {
      type: Array,
      required: true
    }
  },
  methods: {
    getPoster(exhibition) {
      return this.$store.state.sdk.getUrl(exhibition.poster._default.path);
    }
  }
};
</script>
<style lang="scss">
.archive-exhibitions {
  .expansion-panel {
    padding: 0 !important;
  }
}
</style>
