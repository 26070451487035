<template>
  <svg
    class="exhibitions-banner-coming-soon-de"
    data-name="Ebene 1"
    viewBox="0 0 136 80"
    xmlns="http://www.w3.org/2000/svg"
  >
    <polygon
      class="cls-1"
      points="136 76.86 136 44.52 58.88 0 2.88 0 136 76.86"
    />
    <g class="cls-2">
      <path
        class="cls-3"
        d="M53.93,6.89,56.6,8.43c2.19,1.26,2.86,2.91,1.49,5.48L56.31,17c-1.54,2.47-3.3,2.71-5.49,1.45L48.16,16.9Zm-2.88,9.42.66.39c1,.61,1.95.46,2.82-1L56.05,13c.81-1.41.68-2.27-.51-3l-.67-.38Z"
      />
      <path
        class="cls-3"
        d="M55.86,21.35l5.78-10,5.24,3-1,1.69-3.33-1.92-1.32,2.3,2.53,1.46-1,1.69-2.54-1.46-1.52,2.63,3.47,2-1,1.7Z"
      />
      <path
        class="cls-3"
        d="M62.9,25.41l5.78-10L71.35,17l-2.06,7.57,0,0L74.85,19l2.67,1.54-5.78,10-1.63-.94,4.54-7.88h0L68,28.38l-1.44-.83,2.51-9,0,0-4.54,7.88Z"
      />
      <path
        class="cls-3"
        d="M73.68,31.64l5.78-10,2.09,1.21-.76,7.47h0l3.42-5.93,1.75,1-5.78,10-1.83-1.06.8-8.15h0l-3.73,6.46Z"
      />
      <path
        class="cls-3"
        d="M81.36,36.07l8.26-8.58,2.45,1.42L88.7,40.31l-1.83-1.06.73-2.38L85,35.34,83.2,37.13ZM91.63,25.64l-.91,1.58-1.67-1L90,24.68Zm-5.35,8.21,1.9,1.09,1.41-4.58,0,0Zm8-6.69-.91,1.58-1.66-1,.91-1.58Z"
      />
      <path
        class="cls-3"
        d="M93.2,39c-.64,1.11-.33,1.7.43,2.14s1.42.41,2.06-.7l.39-.66L98,40.83l-.24.42c-1.42,2.46-3.24,2.6-5.1,1.53S90,40.07,91.43,37.6L93.49,34c1.43-2.47,3.24-2.6,5.1-1.53s2.65,2.71,1.22,5.18v0l-1.91-1.11.16-.27c.64-1.11.33-1.7-.44-2.14s-1.42-.41-2.06.7Z"
      />
      <path
        class="cls-3"
        d="M97.55,45.42l5.78-10,1.92,1.11-2.31,4,2.7,1.56,2.31-4,1.91,1.1-5.78,10-1.91-1.1,2.5-4.33L102,42.2l-2.49,4.33Z"
      />
      <path
        class="cls-3"
        d="M114.31,45.46c.22-1.12,0-1.76-.63-2.15a1.13,1.13,0,0,0-1.68.49c-.87,1.51,3.33,4.09,1.52,7.22-1,1.72-2.83,2.17-4.84,1-1.55-.89-2.27-2.44-1.64-4.7l2.12.69c-.48,1.33,0,2.16.53,2.49a1.3,1.3,0,0,0,1.87-.51c1.09-1.89-3.16-4.38-1.55-7.18,1-1.72,2.64-2.21,4.63-1.06a3.42,3.42,0,0,1,1.67,4.2Z"
      />
      <path
        class="cls-3"
        d="M125.19,48l-1,1.69L122,48.43l-4.8,8.32-1.92-1.1,4.8-8.32L117.83,46l1-1.69Z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ExhibitionsBannerComingSoonDe'
};
</script>
<style lang="scss">
.exhibitions-banner-coming-soon-de {
  position: absolute;
  top: 0;
  right: 0;
  width: 136px;
  .cls-1 {
    fill: #ffcd37;
  }
  .cls-2 {
    isolation: isolate;
  }
  .cls-3 {
    fill: #fff;
  }
}
</style>
