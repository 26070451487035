<template>
  <div class="pressarea-exhibition">
    <h4 class="pb-3 text-uppercase">{{ $t('content.pressarea.presstext') }}</h4>
    <p class="pb-3">
      {{ $t('content.pressarea.exhibitionInfo') }}
    </p>
    <div v-if="presskit" class="pb-4">
      <a class="pressarea-exhibition__press-kit" download :href="presskitUrl">
        {{ $t('content.pressarea.download') }}
      </a>
      ({{ $t('content.pressarea.document') }}: {{ presskitType }},
      {{ presskitSize }}kb)
    </div>
    <div v-if="pressImages.length" class="pressarea-exhibition__images mt-4">
      <h4 class="text-uppercase">{{ $t('content.pressarea.pressimage') }}</h4>
      <div v-for="(image, index) of pressImages" :key="index">
        <pressarea-exhibition-image :exhibition-image="image" />
      </div>
    </div>
  </div>
</template>

<script>
import PressareaExhibitionImage from './PressareaExhibitionImage.vue';
export default {
  components: { PressareaExhibitionImage },
  name: 'PressareaExhibition',
  props: {
    exhibition: {
      type: Object,
      require: true
    }
  },
  computed: {
    presskit() {
      const presskit = this.$store.state.sdk.getLanguageProperty(
        this.exhibition,
        'presskit'
      );
      if (presskit?.error) return null;
      return presskit;
    },
    presskitSize() {
      return Math.round((this.presskit?.size ?? 1024) / 1024);
    },
    presskitType() {
      return (this.presskit?.path ?? '').split('.').pop();
    },
    pressImages() {
      return (this.exhibition.gallery ?? []).filter(
        image => image?.is_press_image
      );
    },
    presskitUrl() {
      return this.$store.state.sdk.getUrl(this.presskit.path);
    }
  }
};
</script>
<style lang="scss">
.pressarea-exhibition {
  a {
    color: black;
    text-decoration: underline;
  }
}
</style>
