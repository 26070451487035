<template>
  <div id="pressarea" class="pressarea">
    <column-header red :title="$t('content.pressarea.title')" />
    <div v-if="info" class="pressarea__info markdown" v-html="info" />
    <hr />
    <expansion-panel
      v-for="(exhibition, index) of filteredExhibitions"
      :id="`pressarea-${exhibition.slug}`"
      :key="exhibition.slug"
      :anchor="`pressarea-${exhibition.slug}`"
      class="pressarea__exhibition py-3"
      red
      subpage="pressarea"
    >
      <template v-slot:header>
        <div class="pb-4">
          <h4 v-if="exhibition.artist">
            {{ exhibition.artist }}
          </h4>
          <h4 v-else>{{ getTitle(exhibition) }}</h4>
          <span v-if="exhibition.artist">{{ getTitle(exhibition) }}</span>
          <div v-if="exhibition.start_date" class="date">
            {{ $store.state.sdk.getFormatedDate(exhibition.start_date) }}
            <span v-if="exhibition.end_date">
              -
              {{ $store.state.sdk.getFormatedDate(exhibition.end_date) }}
            </span>
          </div>
        </div>
        <is-ready
          v-if="index === filteredExhibitions.length - 1"
          content-name="pressarea"
        />
      </template>
      <template>
        <pressarea-exhibition :exhibition="exhibition" />
      </template>
    </expansion-panel>
  </div>
</template>

<script>
import ColumnHeader from '../ColumnHeader.vue';
import ExpansionPanel from '../expansionPanel/ExpansionPanel.vue';
import PressareaExhibition from './PressareaExhibition.vue';
import IsReady from '../IsReady.vue';
import marked from 'marked';

export default {
  name: 'Pressarea',
  components: {
    ColumnHeader,
    ExpansionPanel,
    PressareaExhibition,
    IsReady
  },
  data() {
    return {
      exhibitions: []
    };
  },
  mounted() {
    this.loadPressarea();
  },
  computed: {
    filteredExhibitions() {
      return this.exhibitions.reduce((list, curr) => {
        if (this.getTitle(curr)) list.push(curr);
        return list;
      }, []);
    },
    info() {
      return marked(
        this.$store.state.sdk.getLanguageProperty(
          this.$store.state.home,
          'pressarea'
        ) ?? ''
      );
    }
  },
  methods: {
    async loadPressarea() {
      this.exhibitions = await this.$store.state.sdk.getFile(
        `/aggregations/exhibitions/pressarea/de_DE/index.json`
      );
    },
    getTitle(record) {
      return this.$store.state.sdk.getLanguageProperty(record, 'title');
    },
    presskitUrl(exhibition) {
      const presskit = this.$store.state.sdk.getLanguageProperty(
        exhibition,
        'presskit'
      );
      if (presskit?.error) return null;
      return presskit;
    },
    getPresskitSize(exhibition) {
      return Math.round((this.presskitUrl(exhibition)?.size ?? 1024) / 1024);
    },
    getPresskitType(exhibition) {
      return (this.presskitUrl(exhibition)?.path ?? '').split('.').pop();
    }
  }
};
</script>
<style lang="scss">
.pressarea {
  .pressarea__info {
    margin: 0 1rem 20px 1rem;
  }

  hr {
    margin-bottom: 40px;
    margin-top: 40px;
  }

  .pressarea__exhibition {
    a {
      color: black;
      text-decoration: underline;
    }
  }
}
</style>
