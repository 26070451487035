<template>
  <div class="news">
    <news-gallery />
    <hr class="mx-3" />
    <column-header red :title="$t('content.news.title')" />
    <expansion-panel
      v-for="(message, index) of getFilteredNews"
      :id="message._leancms.slug"
      :key="message._leancms.slug"
      :anchor="message._leancms.slug"
      class="news-message pb-3"
      red
      subpage="aktuelles"
    >
      <template v-slot:header>
        <div class="news__date date">
          {{ $store.state.sdk.getFormatedDate(message.date) }}
        </div>
        <h4 class="bold uppercase">{{ getTitle(message) }}</h4>
        <is-ready
          v-if="index === getFilteredNews.length - 1"
          content-name="news"
        />
      </template>
      <template>
        <gallery-carousel
          v-if="getGallery(message).length"
          :gallery="getGallery(message)"
          red
        />
        <div class="markdown" v-html="getContent(message)" />
      </template>
    </expansion-panel>
    <div v-if="!getFilteredNews.length" class="news-empty-message">
      <is-ready content-name="news" />
      <h4>{{ getEmptyMessageTitle }}</h4>
      <div class="markdown" v-html="getEmptyMessageContent" />
    </div>
  </div>
</template>

<script>
import ColumnHeader from '@/components/ColumnHeader';
import ExpansionPanel from '@/components/expansionPanel/ExpansionPanel';
import GalleryCarousel from '@/components/gallery/GalleryCarousel';
import IsReady from '@/components/IsReady';
import NewsGallery from './NewsGallery';
import marked from 'marked';

export default {
  name: 'News',
  components: {
    ColumnHeader,
    ExpansionPanel,
    GalleryCarousel,
    IsReady,
    NewsGallery
  },
  data() {
    return {
      news: [],
      emptyNews: []
    };
  },
  mounted() {
    this.loadNews();
  },
  computed: {
    getFilteredNews() {
      return this.news.reduce((list, curr) => {
        if (this.getTitle(curr) && this.getContent(curr)) list.push(curr);
        return list;
      }, []);
    },
    getEmptyMessageTitle() {
      return this.$store.state.sdk.getLanguageProperty(
        this.emptyNews[0],
        'title'
      );
    },
    getEmptyMessageContent() {
      return marked(
        this.$store.state.sdk.getLanguageProperty(
          this.emptyNews[0],
          'content'
        ) ?? ''
      );
    }
  },
  methods: {
    async loadNews() {
      this.news = await this.$store.state.sdk.getFile(
        `/aggregations/news/active/de_DE/index.json`
      );
      this.emptyNews = await this.$store.state.sdk.getFile(
        `/aggregations/contents/current/de_DE/index.json`
      );
    },
    getTitle(record) {
      return this.$store.state.sdk.getLanguageProperty(record, 'title');
    },
    getContent(record) {
      return marked(
        this.$store.state.sdk.getLanguageProperty(record, 'content')
      );
    },
    getGallery(news) {
      return news.gallery?.filter(image => !image.error) ?? [];
    }
  }
};
</script>
<style>
.news .expansion-panel:last-child {
  min-height: 100%;
}
.news .news__date {
  padding-top: 32px;
}
.news .news-empty-message {
  padding-left: 1rem;
  padding-right: 1rem;
}
.news .news-empty-message h4 {
  text-transform: uppercase;
  margin-bottom: 22px;
}
</style>
