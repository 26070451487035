<template>
  <div>
    <b-carousel class="gallery-carousel my-2" :controls="gallery.length > 1">
      <b-carousel-slide v-for="image of gallery" :key="getSlug(image)">
        <template #img>
          <image-slide
            :blue="blue"
            :green="green"
            :image="image"
            :red="red"
            @click.native="showBigCarousel = !showBigCarousel"
          />
        </template>
      </b-carousel-slide>
    </b-carousel>
    <b-modal
      v-model="showBigCarousel"
      class="big-carousel"
      hide-footer
      size="xl"
    >
      <b-carousel
        class="big-gallery-carousel my-2"
        :controls="true"
        :interval="1000000"
      >
        <b-carousel-slide v-for="image of gallery" :key="getSlug(image)">
          <template #img>
            <image-slide
              :blue="blue"
              :green="green"
              :image="image"
              :red="red"
            />
          </template>
        </b-carousel-slide>
      </b-carousel>
    </b-modal>
  </div>
</template>
<script>
import ImageSlide from './ImageSlide';
export default {
  name: 'gallery-carousel',
  components: { ImageSlide },
  data() {
    return {
      showBigCarousel: false
    };
  },
  props: {
    gallery: {
      type: Array,
      default: () => []
    },
    red: {
      default: () => null
    },
    green: {
      default: () => null
    },
    blue: {
      default: () => null
    }
  },
  methods: {
    isActive(value) {
      return value || typeof value === 'string';
    },
    getSlug(image) {
      return image._leancms?.slug ?? '';
    }
  }
};
</script>
