<template>
  <div id="content-wrapper">
    <div id="content-spacer-mobile" />
    <div id="content">
      <component
        :is="component.componentName"
        v-for="(component, index) of filterNavigationList"
        :id="component.route"
        :key="index"
        class="column"
        :class="{ 'column--inactive': isInactive(component.route, index) }"
      />
      <div class="spacer d-none d-md-block" />
      <div class="spacer d-none d-lg-block" />
      <div class="spacer d-none d-xl-block" />
    </div>
  </div>
</template>

<script>
import Archive from '@/components/archive/Archive';
import Contact from '@/components/Contact';
import Editions from '@/components/editions/Editions.vue';
import Events from '@/components/Events.vue';
import Exhibitions from '@/components/exhibitions/Exhibitions.vue';
import Institution from '@/components/Institution.vue';
import News from '@/components/news/News';
import Pressarea from '@/components/pressarea/Pressarea.vue';
import { mapMutations, mapState } from 'vuex';

export default {
  components: {
    Archive,
    Contact,
    Editions,
    Events,
    Exhibitions,
    Institution,
    News,
    Pressarea
  },
  computed: {
    ...mapState({
      navigationList: state => state.navigation.links,
      // use in watch
      isReady: state => state.ready
    }),
    routerParams() {
      return this.$route.params;
    },
    filterNavigationList() {
      return this.navigationList.filter(navItem => navItem.componentName);
    }
  },
  async created() {
    this.setHomeData(
      (
        await this.$store.state.sdk.getFile(
          `/aggregations/home/all/de_DE/index.json`
        )
      ).pop()
    );
  },
  watch: {
    routerParams: {
      // listens for url changes and evaluate the slug
      handler(newParams) {
        this.setCurrentFocusComponent(newParams);
      }
    },
    isReady: {
      deep: true,
      handler(value) {
        // look all subpages elements are loaded
        if (Object.values(value).find(status => !status) === undefined) {
          this.setCurrentFocusComponent(this.routerParams);
        }
      }
    }
  },
  methods: {
    ...mapMutations(['setCurrentComponentId', 'setHomeData']),
    setCurrentFocusComponent(newParams) {
      if (newParams && newParams.slug) {
        // add hash for column internal scrolling
        const searchedSlug =
          // look for has a hash
          this.$route.hash ? newParams.slug + this.$route.hash : newParams.slug;
        let navElement = this.getNavElement(searchedSlug);
        if (!navElement) {
          navElement = this.getNavElement(newParams.slug);
        }

        if (navElement) {
          this.setCurrentComponentId(navElement.id);
        }
      } else if (typeof newParams === 'object') {
        // need when default route without parameter exist
        this.setCurrentComponentId(this.navigationList[0].id);
      }
    },
    getNavElement(searchedSlug) {
      return this.navigationList.find(
        navEle => navEle.route + (navEle.hash || '') === searchedSlug
      );
    },
    isInactive(route, index) {
      if (!this.$route.params.slug && index == 0) {
        return false;
      }
      return this.$route.params.slug != route;
    }
  }
};
</script>
<style lang="scss">
@import 'src/style/main';

#content-wrapper {
  height: 100%;
  position: fixed;
  max-width: 100%;

  #content {
    height: 100%;
    overflow-x: hidden;
    scroll-behavior: smooth;

    @media (min-width: #{map-get($grid-breakpoints, 'sm')}) {
      display: flex;
      flex-direction: row;
    }
    > * {
      min-width: 100%;
      @media (min-width: #{map-get($grid-breakpoints, 'md')}) {
        min-width: 50%;
      }
      @media (min-width: #{map-get($grid-breakpoints, 'lg')}) {
        min-width: 33.33%;
      }
      @media (min-width: #{map-get($grid-breakpoints, 'xl')}) {
        min-width: 25%;
      }
      overflow-y: auto;
    }

    .column {
      border-right: 1px solid gray;
      opacity: 1;
      transition: opacity 0.4s ease-in-out;

      &--inactive {
        opacity: 0.3;
      }
    }

    .spacer {
      flex-grow: 1;
    }
  }
  #content-spacer-mobile {
    @media (max-width: #{map-get($grid-breakpoints, 'sm')}) {
      height: $navigationShadowOffsetX + px;
    }
  }
}
</style>
