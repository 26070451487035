<template>
  <div class="slide-image">
    <div class="slide-image__content">
      <span
        class="slide-image__content_image"
        :style="{ 'background-image': `url(${imagePath})` }"
      />
    </div>
    <div v-if="caption" class="slide-image__text small">
      <div>{{ caption }}</div>
      <div v-if="copyright" class="slide-image__copyright">
        &#169; {{ copyright }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'image-slide',
  props: {
    image: {
      type: Object,
      required: true
    }
  },
  computed: {
    imagePath() {
      const image = this.image.image ?? this.image;
      return this.$store.state.sdk.getUrl(
        !image.large?.error ? image.large.path : image._default.path
      );
    },
    caption() {
      return this.$store.state.sdk.getLanguageProperty(this.image, 'caption');
    },
    copyright() {
      return this.$store.state.sdk.getLanguageProperty(this.image, 'copyright');
    }
  },
  methods: {
    isActive(value) {
      return value || typeof value === 'string';
    }
  }
};
</script>
<style>
.slide-image {
  max-width: 100vh;
  margin: auto;
}
/* heck for 4:3 image crop */
.slide-image__content {
  width: 100%;
  overflow: hidden;
  margin: 0;
  padding-top: 75%;
  position: relative;
}

.slide-image__content_image {
  height: 100%;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.slide-image__text {
  text-align: center;
}
.slide-image__text div {
  min-height: 42px;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
