<template>
  <div id="app">
    <div id="navigation">
      <navigation-desktop />
      <navigation-mobile />
    </div>
    <router-view class="view" />
  </div>
</template>
<script>
import NavigationDesktop from './components/navigation/NavigationDesktop';
import NavigationMobile from './components/navigation/NavigationMobile';
export default {
  components: {
    NavigationMobile,
    NavigationDesktop
  },
  created() {
    document.title = this.$t('title');
  }
};
</script>
<style lang="scss">
@import 'style/variables';
@import 'style/grid';

body {
  margin: 0;
}
#navigation {
  min-width: $navigationWidth + px;
  .text-dark {
    color: black !important;
  }
}
#content-wrapper {
  @media (min-width: #{map-get($grid-breakpoints, 'sm')}) {
    max-width: calc(100% - #{$navigationWidth+$navigationShadowOffsetX}px);
    left: ($navigationWidth + ($navigationShadowOffsetX * 2)) + px;
  }
}

::-webkit-scrollbar {
  width: 5px; /* for vertical scrollbars */
  height: 12px; /* for horizontal scrollbars */
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.3);
}
</style>
