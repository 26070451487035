<template>
  <div id="editions" class="editions">
    <column-header red :title="$t('content.editions.title')" />
    <div class="editions__info">
      <p v-if="info" class="markdown" v-html="info"></p>
      <div
        v-for="(artist, i) of artists"
        :key="i"
        class="editions-info__artists"
      >
        <router-link v-if="artist" :to="`editionen#editions-${artist.year}`">
          {{ artist.name }}
        </router-link>
      </div>
    </div>
    <hr class="mt-5" />
    <editions-year
      v-for="(year, index) of sortedByYears"
      :key="`year-${index}`"
      class="mt-4"
      :editions="year"
      :last-element="earliestYear === Number(index)"
      :year="index"
    />
    <is-ready v-if="!sortedByYears.length" contentName="editions" />
  </div>
</template>

<script>
import ColumnHeader from '../ColumnHeader.vue';
import EditionsYear from './EditionsYear.vue';
import IsReady from '../IsReady.vue';
import dayjs from 'dayjs';
import marked from 'marked';

export default {
  name: 'Editions',
  components: {
    ColumnHeader,
    EditionsYear,
    IsReady
  },
  data() {
    return {
      editions: [],
      earliestYear: 1900
    };
  },
  mounted() {
    this.load();
  },
  computed: {
    sortedByYears() {
      const yearList = {};
      this.editions.forEach(ele => {
        const year = dayjs(ele.year).year();
        yearList[year] = [...(yearList[year] ?? []), ele];
        this.earliestYear =
          this.earliestYear < dayjs(ele.year).year()
            ? dayjs(ele.year).year()
            : this.earliestYear;
      });
      return yearList;
    },
    artists() {
      const artists = {};
      this.editions.forEach(edition => {
        artists[
          `${edition.firstname.toLowerCase()}-${edition.lastname.toLowerCase()}`
        ] = {
          name: `${edition.firstname} ${edition.lastname}`,
          year: dayjs(edition.year).year()
        };
      });
      return artists;
    },
    info() {
      return marked(
        this.$store.state.sdk.getLanguageProperty(
          this.$store.state.home,
          'editionen'
        ) ?? ''
      );
    }
  },
  methods: {
    async load() {
      this.editions = await this.$store.state.sdk.getFile(
        `/aggregations/editionen/all/de_DE/index.json`
      );
    },
    getYear(edition) {
      return dayjs(edition.year).year();
    }
  }
};
</script>
<style lang="scss">
.editions {
  .editions__info {
    padding-left: 1rem;
    padding-right: 1rem;
    .editions-info__artists {
      text-transform: uppercase;
    }
  }
  a {
    color: black;
    text-decoration: underline;
    &:focus,
    &:hover {
      color: black;
    }
  }
}
</style>
