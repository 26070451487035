<template>
  <div class="expansion-panel-svg">
    <svg
      v-if="collapse"
      class="expansion-panel-svg--collapse"
      data-name="Ebene 1"
      viewBox="10 0 479 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M249.5,2A6.5,6.5,0,1,0,256,8.5,6.54,6.54,0,0,0,249.5,2Zm0,12A5.5,5.5,0,1,1,255,8.5,5.5,5.5,0,0,1,249.5,14ZM253,9h-3v3h-1V9h-3V8h3V5h1V8h3Zm236,2.5H268L249.5,30,231,11.5H10v-1H231.4l18.1,18.1,18.1-18.1H489Z"
      />
    </svg>
    <svg
      v-else
      class="expansion-panel-svg--open"
      data-name="Ebene 1"
      viewBox="10 0 479 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M249.5,2A6.5,6.5,0,1,0,256,8.5,6.54,6.54,0,0,0,249.5,2Zm0,12A5.5,5.5,0,1,1,255,8.5,5.5,5.5,0,0,1,249.5,14ZM246,9V8h7V9Zm243,2.5H268L249.5,30,231,11.5H10v-1H231.4l18.1,18.1,18.1-18.1H489Z"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'ExpansionPanelSvg',
  props: {
    collapse: {
      type: Boolean,
      default: () => true
    }
  }
};
</script>
<style scoped>
.expansion-panel-svg--open {
  transform: rotate(180deg);
}
</style>
