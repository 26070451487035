<template>
  <svg
    class="exhibitions-banner-coming-soon-en"
    data-name="Ebene 1"
    viewBox="0 0 136 80"
    xmlns="http://www.w3.org/2000/svg"
  >
    <polygon
      class="cls-1"
      points="136 76.86 136 44.52 58.88 0 2.88 0 136 76.86"
    />
    <g class="cls-2">
      <path
        class="cls-3"
        d="M47,12.27c-.64,1.11-.33,1.7.43,2.14s1.42.41,2.06-.7l.39-.66,1.91,1.1-.24.42c-1.42,2.47-3.24,2.6-5.1,1.53s-2.65-2.71-1.22-5.18l2.06-3.58c1.43-2.47,3.24-2.6,5.1-1.53S55,8.52,53.61,11l0,0L51.68,9.91l.16-.27c.64-1.11.33-1.7-.43-2.14s-1.43-.41-2.07.7Z"
      />
      <path
        class="cls-3"
        d="M55,11.79c1.42-2.47,3.24-2.6,5.1-1.53s2.64,2.71,1.22,5.18L59.24,19c-1.42,2.46-3.24,2.6-5.09,1.53s-2.65-2.72-1.23-5.18Zm-.3,4.93c-.64,1.11-.33,1.69.43,2.13s1.43.42,2.07-.69l2.35-4.07c.64-1.11.33-1.7-.43-2.14s-1.42-.41-2.07.7Z"
      />
      <path
        class="cls-3"
        d="M59.05,23.19l5.78-10,2.67,1.55L65.44,22.3h0L71,16.74l2.68,1.55-5.78,10-1.63-.95,4.54-7.87,0,0-6.58,6.7-1.44-.83,2.51-9,0,0-4.55,7.87Z"
      />
      <path class="cls-3" d="M69.83,29.41l5.78-10,1.91,1.1-5.78,10Z" />
      <path
        class="cls-3"
        d="M73.68,31.64l5.78-10,2.09,1.21-.76,7.47h0l3.42-5.93,1.75,1-5.78,10-1.83-1.06.8-8.15h0l-3.73,6.46Z"
      />
      <path
        class="cls-3"
        d="M87.3,38.39A2.71,2.71,0,0,1,85,38.33c-1.86-1.07-2.65-2.71-1.22-5.18l2.06-3.58C87.22,27.11,89,27,90.89,28S93.25,30.9,92,33.22l-1.92-1.11c.75-1.29.51-2-.18-2.37s-1.42-.42-2.06.69L85.5,34.5c-.65,1.11-.34,1.7.43,2.14a1.38,1.38,0,0,0,2-.72l.35-.61-1.34-.77L87.81,33l3.26,1.88-3,5.13-1-.58Z"
      />
      <path
        class="cls-3"
        d="M102,38.35c.23-1.12,0-1.77-.62-2.15a1.14,1.14,0,0,0-1.69.49c-.87,1.51,3.33,4.08,1.52,7.22-1,1.72-2.82,2.17-4.83,1-1.56-.89-2.27-2.44-1.65-4.7l2.12.68c-.48,1.34,0,2.17.54,2.5a1.3,1.3,0,0,0,1.86-.51c1.09-1.89-3.16-4.38-1.54-7.18,1-1.72,2.64-2.21,4.62-1.07A3.46,3.46,0,0,1,104,38.85Z"
      />
      <path
        class="cls-3"
        d="M105.05,40.69c1.43-2.46,3.24-2.6,5.1-1.53s2.65,2.72,1.22,5.18l-2.06,3.58c-1.43,2.47-3.24,2.6-5.1,1.53s-2.65-2.71-1.22-5.18Zm-.29,4.93c-.64,1.11-.34,1.7.43,2.14s1.42.41,2.06-.7L109.6,43c.64-1.11.33-1.69-.43-2.13s-1.42-.42-2.06.69Z"
      />
      <path
        class="cls-3"
        d="M112.76,45.14c1.42-2.47,3.23-2.6,5.09-1.53s2.65,2.71,1.23,5.18L117,52.37c-1.42,2.47-3.24,2.6-5.1,1.53s-2.64-2.71-1.22-5.18Zm-.3,4.93c-.64,1.11-.33,1.7.43,2.14s1.42.41,2.06-.7l2.36-4.07c.64-1.11.33-1.7-.43-2.14s-1.43-.41-2.07.7Z"
      />
      <path
        class="cls-3"
        d="M116.82,56.54l5.77-10,2.1,1.21-.77,7.47,0,0,3.43-5.94,1.74,1-5.78,10-1.83-1.05.81-8.15,0,0-3.73,6.46Z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ExhibitionsBannerComingSoonEn'
};
</script>
<style lang="scss">
.exhibitions-banner-coming-soon-en {
  position: absolute;
  top: 0;
  right: 0;
  width: 136px;
  .cls-1 {
    fill: #ffcd37;
  }
  .cls-2 {
    isolation: isolate;
  }
  .cls-3 {
    fill: #fff;
  }
}
</style>
