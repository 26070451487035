<template>
  <div id="navigation-mobile">
    <b-navbar
      id="navigation__mobile"
      class="d-sm-none shadow-lg"
      toggleable="sm"
    >
      <b-navbar-brand to="/">
        <h1>Städtische Galerie<br />Wolfsburg</h1>
      </b-navbar-brand>
      <b-navbar-toggle target="navigation-sidebar-mobile">
        <template>
          <hamburger-svg />
        </template>
      </b-navbar-toggle>
    </b-navbar>
    <b-sidebar
      id="navigation-sidebar-mobile"
      v-model="isMobileSidebarOpen"
      class="d-block d-sm-none"
      no-close-on-backdrop
      no-close-on-esc
      @hidden="goTo()"
    >
      <navigation-sidebar-content @changeRoute="nextRoute = $event" />
    </b-sidebar>
  </div>
</template>

<script>
import NavigationSidebarContent from './NavigationSidebarContent';
import HamburgerSvg from './HamburgerSvg';

export default {
  components: {
    HamburgerSvg,
    NavigationSidebarContent
  },
  data() {
    return {
      nextRoute: '',
      isMobileSidebarOpen: false
    };
  },
  methods: {
    goTo() {
      if (this.$route.path.indexOf(this.nextRoute) < 0) {
        this.$router.push(this.nextRoute);
      }
    }
  },
  watch: {
    nextRoute: {
      handler() {
        this.isMobileSidebarOpen = false;
      }
    }
  }
};
</script>

<style lang="scss">
@import '../../style/main';
.navbar {
  z-index: 1;
}
.navbar.shadow-lg {
  box-shadow: $navigationShadow;
  -webkit-box-shadow: $navigationShadow;
  -moz-box-shadow: $navigationShadow;
}
#navigation__mobile .navbar-toggler {
  border: none;
  outline: none;
}

#navigation-sidebar-mobile {
  width: 100% !important;
}
</style>
