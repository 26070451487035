<template>
  <div class="contact p-0">
    <div v-for="(item, index) of getFilteredContact" :key="item._leancms.slug">
      <expansion-text-panel
        :id="`contact-${item._leancms.slug}`"
        :anchor="`contact-${item._leancms.slug}`"
        class="contact__content px-3"
        red
        subpage="kontakt"
      >
        <template v-slot:header>
          <column-header red :title="title(item)" />
        </template>
        <template>
          <div class="markdown" v-html="content(item)" />
          <is-ready
            v-if="index === getFilteredContact.length - 1"
            content-name="contact"
          />
        </template>
      </expansion-text-panel>
      <hr />
    </div>
  </div>
</template>

<script>
import ColumnHeader from './ColumnHeader';
import ExpansionTextPanel from './expansionPanel/ExpansionTextPanel';
import marked from 'marked';
import { mapMutations } from 'vuex';
import IsReady from './IsReady';

export default {
  name: 'Contact',
  components: {
    IsReady,
    ColumnHeader,
    ExpansionTextPanel
  },
  data() {
    return {
      contact: []
    };
  },
  mounted() {
    this.loadContact();
  },
  computed: {
    getFilteredContact() {
      return this.contact.reduce((list, curr) => {
        if (this.title(curr) && this.content(curr)) list.push(curr);
        return list;
      }, []);
    }
  },
  methods: {
    ...mapMutations(['setIsReady']),
    async loadContact() {
      this.contact = await this.$store.state.sdk.getFile(
        `/aggregations/contents/contact/de_DE/index.json`
      );
    },
    title(item) {
      return this.$store.state.sdk.getLanguageProperty(item, 'title');
    },
    content(item) {
      return marked(this.$store.state.sdk.getLanguageProperty(item, 'content'));
    }
  }
};
</script>
<style>
.contact__content {
  word-break: break-word;
}

.contact div:last-child {
  min-height: 100%;
}
</style>
