<template>
  <div class="is-ready" />
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  props: {
    contentName: {
      type: String,
      required: true
    }
  },
  mounted() {
    this.setIsReady(this.contentName);
  },
  methods: {
    ...mapMutations(['setIsReady'])
  }
};
</script>
