import LeancmsSdk from './LeancmsSdk';

export default class WolfsburgSdk extends LeancmsSdk {
  language = 'de_DE';

  constructor(domain, language = 'de_DE') {
    super(domain);
    this.language = language;
  }

  getLanguageProperty(record, prefix) {
    if (this.language === 'en_UK') return record?.[prefix + '_en'];
    if (this.language === 'de_DE') return record?.[prefix + '_de'];
    return '';
  }

  getFormatedDate(oriDate) {
    const language = this.language.replace('_', '-');
    const date = new Date(oriDate);

    return `${date.toLocaleDateString(language, {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    })}`;
  }
}
