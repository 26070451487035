import '@babel/polyfill';
import 'mutationobserver-shim';
import Vue from 'vue';
import './plugins/bootstrap-vue';
import router from './router';
import store from './store';
import i18n from './plugins/i18n';
import './plugins/vueScrollTo';
import App from './App.vue';
import dayjs from 'dayjs';
import '@mdi/font/css/materialdesignicons.css';

import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

Vue.config.productionTip = false;

fetch('/env.json').then(async response => {
  const env = await response.json();
  router.base = env.cdn;

  const language =
    window.location.hostname.indexOf('english') > -1 ? 'en_UK' : 'de_DE';

  i18n.locale = language || env.locale;
  i18n.fallbackLocale = env.fallback_locale || 'de_DE';

  store.commit('initSdk', { language: i18n.locale, cdn: env.cdn });

  new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
  }).$mount('#app');
});
