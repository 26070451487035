<template>
  <div id="kalender" class="calender">
    <column-header red :title="$t('content.calendar.title')" />
    <div class="calender-content">
      <expansion-panel
        v-for="(item, index) of getFilteredCalender"
        :id="item._leancms.slug"
        :key="index"
        :anchor="item._leancms.slug"
        class="calender-content__item pb-3"
        red
        subpage="veranstaltungen"
      >
        <template v-slot:header>
          <div v-if="item.date" class="date">
            {{ $store.state.sdk.getFormatedDate(item.date) }}
            <span v-if="item.expiration">
              -
              {{ $store.state.sdk.getFormatedDate(item.expiration) }}</span
            >
          </div>
          <h4 class="bold uppercase">{{ getTitle(item) }}</h4>
          <h4 class="uppercase">{{ subtitle(item) }}</h4>
          <is-ready v-if="index === events.length - 1" content-name="events" />
        </template>
        <template>
          <gallery-carousel v-if="galleryLength" :gallery="item.gallery" red />
          <div class="markdown" v-html="getContent(item)" />
        </template>
      </expansion-panel>
      <div v-if="!getFilteredCalender.length" class="calender-empty-message">
        <is-ready content-name="events" />
        <h4>{{ getEmptyMessageTitle }}</h4>
        <div class="markdown" v-html="getEmptyMessageContent" />
      </div>
    </div>
  </div>
</template>

<script>
import ExpansionPanel from './expansionPanel/ExpansionPanel';
import GalleryCarousel from './gallery/GalleryCarousel';
import ColumnHeader from './ColumnHeader';
import marked from 'marked';
import IsReady from './IsReady';

export default {
  components: { IsReady, ColumnHeader, GalleryCarousel, ExpansionPanel },
  data() {
    return {
      events: [],
      emptyEventMessage: []
    };
  },
  mounted() {
    this.loadEvents();
  },
  computed: {
    getFilteredCalender() {
      return this.events.reduce((list, curr) => {
        if (this.getTitle(curr) && this.getContent(curr)) list.push(curr);
        return list;
      }, []);
    },
    getEmptyMessageTitle() {
      return this.$store.state.sdk.getLanguageProperty(
        this.emptyEventMessage[0],
        'title'
      );
    },
    getEmptyMessageContent() {
      return marked(
        this.$store.state.sdk.getLanguageProperty(
          this.emptyEventMessage[0],
          'content'
        ) ?? ''
      );
    }
  },
  methods: {
    galleryLength(item) {
      return (item.gallery ?? []).length;
    },
    async loadEvents() {
      this.events = await this.$store.state.sdk.getFile(
        `/aggregations/events/active/de_DE/index.json`
      );
      this.emptyEventMessage = await this.$store.state.sdk.getFile(
        `/aggregations/contents/calendar/de_DE/index.json`
      );
    },
    getTitle(item) {
      return this.$store.state.sdk.getLanguageProperty(item, 'title');
    },
    subtitle(item) {
      return this.$store.state.sdk.getLanguageProperty(item, 'subtitle_de');
    },
    getContent(item) {
      return marked(this.$store.state.sdk.getLanguageProperty(item, 'content'));
    }
  }
};
</script>
<style>
.calender {
  height: 100%;
}
.calender .calender-content {
  height: 100%;
}
.calender .calender-content__item {
  padding-top: 32px;
}
.calender .calender-content .expansion-panel:last-child {
  height: 100%;
  min-height: 100%;
}
.calender .calender-empty-message {
  padding-left: 1rem;
  padding-right: 1rem;
}
.calender .calender-empty-message h4 {
  text-transform: uppercase;
  margin-bottom: 22px;
}
</style>
