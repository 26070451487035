<template>
  <div class="editions-year">
    <expansion-panel
      :id="`editions-${year}`"
      :anchor="`editions-${year}`"
      class="editions__year py-3"
      red
      subpage="editionen"
    >
      <template v-slot:header>
        <h2 class="color-r">{{ year }}</h2>
      </template>
      <template>
        <editions-year-edition
          v-for="(edition, index) of editions"
          :key="index"
          :edition="edition"
          :year="year"
        />
      </template>
    </expansion-panel>
    <is-ready v-if="lastElement" contentName="editions" />
  </div>
</template>

<script>
import ExpansionPanel from '../expansionPanel/ExpansionPanel.vue';
import IsReady from '../IsReady.vue';
import EditionsYearEdition from './EditionsYearEdition.vue';

export default {
  name: 'EditionsYear',
  components: {
    ExpansionPanel,
    IsReady,
    EditionsYearEdition
  },
  props: {
    editions: {
      type: Array,
      require: true
    },
    year: {
      type: String,
      require: true
    },
    lastElement: {
      type: Boolean
    }
  }
};
</script>
