<template>
  <div v-if="images.length" class="news-gallery px-3">
    <div v-for="(image, index) of images" :key="index">
      <gallery-carousel :gallery="[image]" />
    </div>
  </div>
</template>

<script>
import GalleryCarousel from '@/components/gallery/GalleryCarousel';

export default {
  name: 'NewsGallery',
  components: {
    GalleryCarousel
  },
  computed: {
    images() {
      return (this.$store.state.home
        ? this.$store.state.home.images
        : []
      ).filter(image => !image?.error);
    }
  }
};
</script>

<style lang="scss">
.news-gallery {
  height: 70vh;
  margin: 2rem 0;
  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
