<template>
  <div class="exhibitions-content">
    <div class="exhibitions-content_video my-3">
      <video
        v-if="exhibition.video"
        controls
        :poster="placeholderImage"
        :src="video"
      >
        {{ $t('content.exhibitions.videoNotLoad') }}
      </video>
    </div>
    <div class="exhibitions-content_description markdown" v-html="content" />
    <gallery-carousel v-if="gallery.length" :gallery="gallery" red />
  </div>
</template>

<script>
import GalleryCarousel from '../gallery/GalleryCarousel';
import marked from 'marked';

export default {
  components: { GalleryCarousel },
  props: {
    exhibition: {
      type: Object,
      required: true
    }
  },
  computed: {
    placeholderImage() {
      let subpath =
        this.exhibition.video?.variations?.['mp4-large'].path ??
        this.exhibition.video?.variations?.['mp4-small'].path ??
        '';
      subpath = subpath.split('/');
      subpath = subpath.splice(0, subpath.length - 1).join('/');
      const path = subpath
        ? `${subpath}/${this.exhibition._leancms.slug}.0000000.jpg`
        : this.exhibition.poster._default?.path;
      return this.$store.state.sdk.getUrl(path);
    },
    content() {
      return marked(
        this.$store.state.sdk.getLanguageProperty(this.exhibition, 'content')
      );
    },
    gallery() {
      return (this.exhibition?.gallery ?? []).filter(image => !image.error);
    },
    video() {
      return this.$store.state.sdk.getUrl(
        this.exhibition.video?.variations._default.path
      );
    }
  }
};
</script>
<style lang="scss">
.exhibitions-content_video video {
  width: 100%;
}
</style>
