<template>
  <div class="institution p-0">
    <div
      v-for="(item, index) of getFilteredInstitution"
      :key="item._leancms.slug"
    >
      <expansion-text-panel
        :anchor="`institution-${item._leancms.slug}`"
        red
        subpage="institutionelles"
      >
        <template v-slot:header>
          <column-header red :title="title(item)" />
        </template>
        <template>
          <div class="institution__content markdown" v-html="content(item)" />
          <is-ready
            v-if="index === getFilteredInstitution.length - 1"
            content-name="institution"
          />
        </template>
      </expansion-text-panel>
      <hr />
    </div>
  </div>
</template>

<script>
import ColumnHeader from './ColumnHeader';
import ExpansionTextPanel from './expansionPanel/ExpansionTextPanel';
import marked from 'marked';
import IsReady from './IsReady';

export default {
  name: 'Institution',
  components: {
    IsReady,
    ExpansionTextPanel,
    ColumnHeader
  },
  data() {
    return {
      institution: []
    };
  },
  mounted() {
    this.loadInstitution();
  },
  computed: {
    getFilteredInstitution() {
      return this.institution.reduce((list, curr) => {
        if (this.title(curr) && this.content(curr)) list.push(curr);
        return list;
      }, []);
    }
  },
  methods: {
    async loadInstitution() {
      this.institution = await this.$store.state.sdk.getFile(
        `/aggregations/contents/institution/de_DE/index.json`
      );
    },
    title(item) {
      return this.$store.state.sdk.getLanguageProperty(item, 'title');
    },
    content(item) {
      return marked(this.$store.state.sdk.getLanguageProperty(item, 'content'));
    }
  }
};
</script>
<style>
.institution__content {
  word-break: break-word;
}
.institution div:last-child {
  min-height: 100%;
}
</style>
