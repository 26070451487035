<template>
  <div id="archiv" class="archive">
    <column-header red :title="$t('content.archive.title')" />
    <div class="archive-btns">
      <category-button
        :isActive="isActiveStandard"
        label="Alles"
        @click.native="toggleStandard"
      />
      <category-button
        :isActive="isActiveStation"
        label="Kunst-Station"
        @click.native="toggleStation"
      />
      <category-button
        :isActive="isActiveDeineKunst"
        label="Deine Kunst"
        @click.native="toggleDeineKunst"
      />
    </div>
    <expansion-panel
      v-for="(year, index) of sortedByYears"
      :id="`archive-${index}`"
      :key="index"
      :anchor="`archive-${index}`"
      class="year py-2"
      subpage="archive"
    >
      <template v-slot:header>
        <h3>{{ index }}</h3>
        <is-ready v-if="Number(index) === earliestYear" contentName="archive" />
      </template>
      <template>
        <archive-exhibitions :exhibitions="year" />
      </template>
    </expansion-panel>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import ColumnHeader from '../ColumnHeader.vue';
import ExpansionPanel from '../expansionPanel/ExpansionPanel.vue';
import ArchiveExhibitions from './ArchiveExhibitions.vue';
import CategoryButton from '../Button.vue';
import IsReady from '../IsReady.vue';
export default {
  components: {
    ArchiveExhibitions,
    CategoryButton,
    ColumnHeader,
    ExpansionPanel,
    IsReady
  },
  name: 'Archive',
  data() {
    return {
      exhibitions: [],
      isActiveStandard: true,
      isActiveStation: false,
      isActiveDeineKunst: false,
      earliestYear: 1900
    };
  },
  async mounted() {
    await this.loadArchiveExhibitions();
  },
  computed: {
    filteredExhibitions() {
      return this.exhibitions
        .reduce((list, curr) => {
          if (
            this.title(curr) &&
            ((this.isActiveStandard &&
              (curr.category === 'standard' || !curr.category)) ||
              (this.isActiveStation && curr.category === 'kunst-station') ||
              (this.isActiveDeineKunst && curr.category === 'deine-kunst'))
          ) {
            if (this.earliestYear < dayjs(curr.start_date).year())
              this.earliestYear = dayjs(curr.start_date).year();
            list.push(curr);
          }
          return list;
        }, [])
        .reverse();
    },
    sortedByYears() {
      const yearList = {};
      this.filteredExhibitions.forEach(ele => {
        const year = dayjs(ele.start_date).year();
        yearList[year] = [...(yearList[year] ?? []), ele];
      });
      return yearList;
    }
  },
  methods: {
    async loadArchiveExhibitions() {
      this.exhibitions = await this.$store.state.sdk.getFile(
        `/aggregations/exhibitions/archive/de_DE/index.json`
      );
    },
    title(item) {
      return this.$store.state.sdk.getLanguageProperty(item, 'title');
    },
    getPoster(exhibition) {
      return this.$store.state.sdk.getUrl(exhibition.poster._default.path);
    },
    toggleStandard() {
      this.isActiveStandard = !this.isActiveStandard;
      this.isActiveStation = false;
      this.isActiveDeineKunst = false;
    },
    toggleStation() {
      this.isActiveStation = !this.isActiveStation;
      this.isActiveStandard = false;
      this.isActiveDeineKunst = false;
    },
    toggleDeineKunst() {
      this.isActiveDeineKunst = !this.isActiveDeineKunst;
      this.isActiveStation = false;
      this.isActiveStandard = false;
    }
  }
};
</script>
<style lang="scss">
@import '../../style/color';
.archive {
  img {
    cursor: pointer;
    width: 100%;
  }
  h3 {
    text-align: center;
  }
  .archive-btns {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 1.5rem;
  }
  .expansion-panel__header--active h3 {
    color: $red;
    font-family: TradeGothic-Bold, RobotCondensed-Bold, sans-serif;
    font-size: 28px;
    line-height: 32px;
    text-align: center;
  }
}
</style>
