<template>
  <div class="navigation-sidebar__content">
    <logo @click.native="goTo({ route: '/#' })" />
    <p v-html="adress" />
    <ul class="navigation-link-list uppercase pt-4">
      <li
        v-for="link of navigationList"
        :key="link.id"
        class="navigation-link"
        @click="goTo(link)"
      >
        <h3
          class="my-1"
          :class="{
            'active-link': link.id === currentComponentId,
            red: link.color === 'red',
            green: link.color === 'green',
            blue: link.color === 'blue'
          }"
        >
          {{ $t(`navigation.links.${link.navigationlabel}`) }}
        </h3>
      </li>
    </ul>
    <ul class="social-icons pt-2">
      <li>
        <a
          href="https://de-de.facebook.com/StaedtischeGalerieWolfsburg/"
          target="_blank"
          title="facebook"
          ><span class="mdi mdi-facebook"></span
        ></a>
      </li>
      <li>
        <a
          href="https://www.instagram.com/staedtischegalerie_wolfsburg/?hl=de"
          target="_blank"
          title="instagram"
          ><span class="mdi mdi-instagram"></span
        ></a>
      </li>
      <li>
        <a
          href="https://www.youtube.com/channel/UClNFuQD5zVizQLc2GmITdKQ"
          target="_blank"
          title="youtube"
          ><span class="mdi mdi-youtube"></span
        ></a>
      </li>
    </ul>
    <div class="pt-4">
      <h3 class="uppercase m-0">{{ $t('navigation.opening_hours') }}</h3>
      <div class="logo-underline my-3" />
      <p class="markdown" v-html="opening_hours" />
    </div>

    <div class="navigation-sidebar-language">
      <a
        :class="{ active: $i18n.locale === 'de_DE' }"
        :href="getLanguageRoute()"
        >DEU</a
      >
      <a
        :class="{ active: $i18n.locale === 'en_UK' }"
        :href="getLanguageRoute('english')"
        >ENG</a
      >
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import logo from './../Logo';
import marked from 'marked';

export default {
  components: {
    logo
  },
  computed: {
    ...mapState({
      navigationList: state => state.navigation.links,
      currentComponentId: state => state.navigation.currentComponentId,
      adress: state => (state.home ? marked(state.home.adress) : ''),
      opening_hours: state =>
        state.home ? marked(state.home.opening_hours) : ''
    })
  },
  methods: {
    goTo(link) {
      this.$emit(
        'changeRoute',
        link.hash ? link.route + link.hash : link.route
      );
    },
    getLanguageRoute(subdomain) {
      subdomain = subdomain ? `${subdomain}.` : '';
      const host = window.location.host
        .replace('www.', '')
        .replace('english.', '');
      return (
        `${window.location.protocol}//` +
        subdomain +
        host +
        window.location.pathname
      );
    }
  }
};
</script>
<style lang="scss">
@import 'src/style/color';
.navigation-sidebar__content {
  padding: 1.5rem;
  padding-right: 5rem;

  .social-icons {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      display: flex;
      margin-right: 5px;

      &:last-of-type {
        margin-right: 0;
      }

      a {
        font-size: 1.4rem;
        padding: 0;

        &:hover {
          color: dimgray;
        }
      }
    }
  }
}
.navigation-link-list {
  padding-left: 0;
}
.navigation-link {
  border-bottom: 1px solid black;
  cursor: pointer;
  list-style-type: none;
}
.navigation-link:last-child {
  border: 0;
}
.active-link {
  font-family: TradeGothic-Bold, RobotCondensed-Bold, sans-serif;
}
.active-link.red {
  color: $red;
}
.active-link.green {
  color: $green;
}
.active-link.blue {
  color: $blue;
}

.navigation-sidebar-language {
  margin-top: 3rem;
}
.navigation-sidebar-language a {
  border: 1px solid gray;
  color: gray;
  margin-right: 1rem;
  padding-left: 0.7rem;
  padding-right: 0.7rem;
  text-decoration: none;
  &.active,
  &:hover {
    background: dimgray;
    color: white;
  }
}
</style>
