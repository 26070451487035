var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"mb-0 uppercase",class:{
      'color-r': _vm.isActive(_vm.red),
      'color-g': _vm.isActive(_vm.green),
      'color-b': _vm.isActive(_vm.blue)
    }},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',{staticClass:"subheader-underline border-color",class:{
      'border-color-r': _vm.isActive(_vm.red),
      'border-color-g': _vm.isActive(_vm.green),
      'border-color-b': _vm.isActive(_vm.blue)
    }})])}
var staticRenderFns = []

export { render, staticRenderFns }